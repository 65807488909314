import { textHighlight, clickInterceptor } from "./listeners";
import { VLIBRAS_ELEMENT_ID } from "./constants";
import "./index.css";

window.addEventListener("load", () => {
  main();
});

function main() {
  const vLibrasContainer = document.body.querySelector("[vw]");

  if (!vLibrasContainer) {
    throw new Error("Failed to create vLibras container");
  }

  vLibrasContainer.addEventListener("click", () => {
    const vLibrasFloatingElement = vLibrasContainer.querySelector(
      "[vw-plugin-wrapper]"
    );

    const isVLibrasOpen = vLibrasFloatingElement?.classList?.contains("active");

    if (isVLibrasOpen) {
      document.addEventListener("mousemove", textHighlight);
      document.addEventListener("click", clickInterceptor, { capture: true });
    } else {
      document.removeEventListener("mousemove", textHighlight);
      document.removeEventListener("click", clickInterceptor);
    }
  });

  // Remove fake element from body
  document.addEventListener("scroll", () => {
    document.getElementById(VLIBRAS_ELEMENT_ID)?.remove();
  });
}
