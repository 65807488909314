import { VLIBRAS_ELEMENT_ID } from "../constants";
import { createFakeElement } from "../utils/create-fake-element";
import { isValidElement } from "./text-highlight";

/**
 * Create a click event from one fake element to make vLibras widget accessible from webComponents.
 * @param event dom mouse event.
 */
export function clickInterceptor(event: MouseEvent): void {
  const targetElement = event.composedPath()[0];

  if (!(targetElement instanceof Element)) {
    return;
  }

  const targetRootNode = targetElement.getRootNode();

  // VLibras needs MouseClick events with x and y to position the interaction button close to the clicked element.
  const isRealMouseEvent = event.isTrusted;
  const isInsideShadow = targetRootNode !== document;

  if (isRealMouseEvent && isInsideShadow) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const containerElement = createFakeElement(targetElement);

    document.getElementById(VLIBRAS_ELEMENT_ID)?.remove();
    document.body.appendChild(containerElement);

    // Trigger a new click event from our newly VLibras-readable element.
    containerElement.dispatchEvent(
      new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
        clientX: event.clientX,
        clientY: event.clientY,
      })
    );
  }
}
