/**
 * Find one element with innerText or textContent from a DOM element
 * @param element DOM element
 * @returns Element with text
 */
export function getTextElement(element: Element): Element | undefined {
  if (!element) {
    return;
  }

  const tag = element.tagName?.toUpperCase();

  // Prevent recursion from getting elements outside the clicked elements
  if (
    tag?.includes("SVG") ||
    tag?.includes("PATH") ||
    tag?.includes("IMG") ||
    tag?.includes("SELECT") ||
    tag === "SY-BUTTON" ||
    tag === "SY-ICON" ||
    tag === "SY-TAG"
  ) {
    return;
  }

  if (
    (element instanceof HTMLElement && element.innerText) ||
    element.shadowRoot?.textContent
  ) {
    return element;
  }

  return getTextElement(
    element.assignedSlot?.parentElement ||
      element.parentElement ||
      element.parentNode ||
      (element as any).host
  );
}
