/**
 * Find a clickable element from a DOM element
 * @param element DOM element
 * @returns Clickable element
 */
export function getLinkElement(element: Element): Element | undefined {
  if (!element) {
    return;
  }

  if (
    document.querySelector("[vp]")?.closest("[vw]")?.contains(element) ||
    document.querySelector(".vp-guide-container")?.contains(element)
  )
    return;

  if (
    (element instanceof HTMLAnchorElement && element.href) ||
    "A" === element.tagName ||
    "BUTTON" === element.tagName ||
    "SY-ICON" === element.tagName ||
    "SY-AVATAR" === element.tagName ||
    element.role === "button"
  ) {
    return element;
  }

  return getLinkElement(
    element.assignedSlot?.parentElement ||
      element.parentElement ||
      element.parentNode ||
      (element as any).host
  );
}
