import { VLIBRAS_ELEMENT_ID } from "../constants";
import { isValidElement } from "../listeners/text-highlight";
import { getLinkElement } from "./get-link-element";
import { getTextElement } from "./get-text-element";

/**
 * Creates one invisible HTMLElement with innerText and the desirable HTMLTag to help vLibras with text translate and click accessibility.
 * @param originalElement clicked dom element.
 * @returns created fake element.
 */
export function createFakeElement(originalElement: Element): HTMLElement {
  const linkElement = getLinkElement(originalElement);
  const textElement = getTextElement(originalElement);

  const fakeElement = document.createElement(
    linkElement
      ? linkElement?.tagName?.toUpperCase() === "A"
        ? "a"
        : "button"
      : "div"
  );

  fakeElement.id = VLIBRAS_ELEMENT_ID;

  if (
    textElement instanceof HTMLElement &&
    (isValidElement(textElement) || linkElement)
  ) {
    // VLibras will use this text to create the translation into Libras.
    fakeElement.innerText =
      textElement?.innerText || textElement.shadowRoot?.textContent || "";
  }

  // VLibras accessibility float button will dispath a click in our fake element.
  fakeElement.addEventListener("click", () => {
    // Our fake element will pass the click event to the original element.
    if (linkElement instanceof HTMLElement) {
      linkElement?.click();
    }

    fakeElement.remove();
  });

  fakeElement.style.display = "none";

  return fakeElement;
}
